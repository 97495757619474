<template>
  <tbody>
    <tr v-for="(item, index) in items" :key="item.id || index">
      <td>{{ item.id }}</td>
      <td v-if="showAreaField">{{ item.area }}</td>
      <td>{{ item.companyName }}</td>
      <td>{{ getLabelEnabled(item.enabled) }}</td>
      <td class="text-center">
        <v-btn
          small
          color="primary"
          :elevation="0"
          @click="handleClickEdit(item.id)"
        >
          Editar
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>

<script>
// Constants
import { ROLES } from '@/constants'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Filters
import { labelActivationsType } from '@/filters/app'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyKeysListItem',
  filters: { labelActivationsType },
  mixins: [uiMixin],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * We must show the "area" field
     *
     * @return {boolean}
     */
    showAreaField() {
      return this.userData.role === ROLES.admin.value
    }
  },
  methods: {
    /**
     * Handle click on edit button
     */
    handleClickEdit(id) {
      if (id) {
        this.routerPushTo({
          path: `/company-keys-form/${id}`
        })
      }
    },
    /**
     * Get the right label to show in the table
     *
     * @param {boolean} enabled - it was used?
     */
    getLabelEnabled(enabled) {
      return enabled ? 'No' : 'Si'
    }
  }
}
</script>
