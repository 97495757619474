<template>
  <PanelSection
    title="Códigos de clientes"
    :hide-action-buttons="false"
    @onClickAddButton="handleClickAddButton"
  >
    <div class="company-keys-list">
      <v-container>
        <CustomDataTable
          :headers="dataTable.headers"
          :items="dataTable.items"
          :items-per-page="dataTable.itemsPerPage"
          :loading="dataTable.loading"
          :page="dataTable.page"
          :search-by="dataTable.searchBy"
          @onPrevPage="handlePrevPage"
          @onNextPage="handleNextPage"
          @onSearch="handleSearch"
          @onChangeItemsPerPage="handleChangeItemsPerPage"
        >
          <template slot-scope="{ items }">
            <CompanyKeysListItem :items="items"></CompanyKeysListItem>
          </template>
        </CustomDataTable>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Constants
import { ROLES } from '@/constants'
// Components
import PanelSection from '@/components/ui/PanelSection'
import CustomDataTable from '@/components/ui/CustomDataTable'
import CompanyKeysListItem from '@/components/elements/companyKeys/CompanyKeysListItem'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { getAllCompanyKeys } from '@/services/companyKeys'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'CompanyKeysList',
  components: { PanelSection, CustomDataTable, CompanyKeysListItem },
  mixins: [uiMixin],
  data() {
    return {
      // Data table
      dataTable: {
        headers: [
          {
            text: 'Código cliente',
            align: 'start',
            value: 'id'
          },
          {
            text: 'Cliente',
            value: 'companyName'
          },
          { text: 'Activado', value: 'enabled' },
          { text: 'Acciones', align: 'center' }
        ],
        items: [],
        itemsPerPage: 20,
        loading: true,
        page: 1,
        searchBy: [
          {
            id: 'code',
            label: 'Código cliente',
            type: 'text',
            typeSearch: 'like'
          },
          {
            id: 'companyName',
            label: 'Cliente',
            type: 'text',
            typeSearch: 'like'
          },
          {
            id: 'enabled',
            format: 'boolean',
            label: 'Activado',
            values: [
              {
                label: 'Si',
                value: false
              },
              {
                label: 'No',
                value: true
              }
            ],
            type: 'select',
            typeSearch: 'exact'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData'])
  },
  async mounted() {
    this.setAttributesToDataTable()
    await this.setDataTableItems()
  },
  methods: {
    /**
     * Handle click on Add button
     */
    handleClickAddButton() {
      this.routerPushTo({
        path: '/company-keys-form'
      })
    },
    /**
     * Handle click on previous page
     *
     * @param {object} paginationOptions - pagination options
     */
    async handlePrevPage(paginationOptions) {
      await this.setDataTableItems(paginationOptions)
      this.dataTable.page = paginationOptions.page || 1
    },
    /**
     * Handle click on next page
     *
     * @param {object} paginationOptions - pagination options
     */
    async handleNextPage(paginationOptions) {
      await this.setDataTableItems(paginationOptions)
      this.dataTable.page = paginationOptions.page || 1
    },
    /**
     * Handle when change number of items per page
     *
     * @param {object} paginationOptions - pagination options
     */
    async handleChangeItemsPerPage(paginationOptions) {
      await this.setDataTableItems(paginationOptions)
      this.dataTable.itemsPerPage = paginationOptions.limit || 20
      this.dataTable.page = 1
    },
    /**
     * Handle make a search
     *
     * @param {object} searchOptions - pagination options
     */
    async handleSearch(searchOptions) {
      const currentSearchOptions = !isNil(searchOptions.query)
        ? {
            order: {
              field: searchOptions.queryField,
              direction: 'asc'
            },
            ...searchOptions
          }
        : null
      await this.setDataTableItems(currentSearchOptions)
      this.dataTable.page = 1
    },
    /**
     * Set attribute to CustomDataTable (when we are admin)
     */
    setAttributesToDataTable() {
      if (this.userData.role === ROLES.admin.value) {
        this.dataTable.headers.splice(1, 0, {
          text: 'Área',
          value: 'area'
        })
        this.dataTable.searchBy.splice(1, 0, {
          id: 'area',
          label: 'Área',
          type: 'text',
          typeSearch: 'exact'
        })
      }
    },
    /**
     * Set items to data table
     *
     * @param {object} paginationOptions - pagination options
     */
    async setDataTableItems(paginationOptions = null) {
      const orderBy = {
        field: 'companyName',
        direction: 'asc'
      }
      const currentPaginationOptions =
        paginationOptions === null
          ? { order: orderBy }
          : { ...paginationOptions, order: orderBy }

      this.dataTable.loading = true
      // Set filter to user role
      if (
        this.userData.role === ROLES.commercial.value &&
        this.userData.meta &&
        Array.isArray(this.userData.meta.areas)
      ) {
        const items = await Promise.all(
          this.userData.meta.areas.map(async area => {
            const itemsArea = await getAllCompanyKeys({
              ...currentPaginationOptions,
              constraints: [['area', '==', area]]
            })
            return itemsArea
          })
        )
        this.dataTable.items = items.flat(1)
      } else if (this.userData.role === ROLES.admin.value) {
        this.dataTable.items = await getAllCompanyKeys(currentPaginationOptions)
      }
      this.dataTable.loading = false
    }
  }
}
</script>
